export const seo = {
  url: 'firma',
  title: {
    pl: 'Firma Transportowa | Kariera | Współpraca',
    en: 'Transport Company | Career | Cooperation',
  },
  desc: {
    pl: 'Poznaj firmę Omida VLS - najbardziej rozpoznawalną polską markę TSL. Dowiedz się więcej o aktualnościach firmy, naszych działaniach i karierze w branży TSL.',
    en: `Welcome to Omida VLS - the most recognizable Polish brand of TSL. Find out more about the company's news, our activities and careers in the TSL industry.`,
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Firma',
    en: 'Company',
  },
  desc: {
    pl: 'Poznaj firmę Omida VLS - najbardziej rozpoznawalną polską markę TSL. Dowiedz się więcej o aktualnościach firmy, naszych działaniach i karierze w branży TSL.',
    en: `Welcome to Omida VLS - the most recognizable Polish brand of TSL. Find out more about the company's news, our activities and careers in the TSL industry.`,
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Learn more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Polska Firma Transportowa, Spedycyjna, Logistyczna',
        en: 'Polish Transport, Forwarding and Logistics Company',
      },
      texts: [
        {
          pl: 'Omida VLS to polska firma transportowa. Zajmujemy się przewozem ładunków <a href="/transport-drogowy/">transportem drogowym</a>, <a href="/transport-kolejowy/">kolejowym</a> i <a href="/transport-intermodalny/">intermodalnym</a>. W ramach naszych usług organizujemy <a href="/transport-calopojazdowy/">transport całopojazdowy</a> oraz <a href="/transport-drobnicowy/">drobnicowy</a>.',
          en: '',
        },
      ],
    },
    {
      headline: {
        pl: 'Polska Firma Transportowa',
        en: 'Polish Transport Company',
      },
      texts: [
        {
          pl: 'Nasze oddziały znajdują się w <a href="/kontakt/#oddzialy">20 miejscowościach w całej Polsce</a>. Od 2019 posiadamy <a href="/logistyka-kontraktowa/">magazyn klasy A pod Warszawą</a>, obsługując całość procesów logistycznych naszych Klientów. Naszą mocną stroną jest <a href="/transport-miedzynarodowy/">transport całopojazdowy FTL po całej Europie</a>. To w tym jesteśmy najlepsi.',
          en: '',
        },
        {
          pl: 'Szukasz transport towaru w Europie? Wybierz Omida VLS. Stawiamy na stały kontakt opiekuna z klientem i <a href="/transport-branze/">specjalizację zespołu w obsłudze danej branży</a>.',
          en: '',
        },
      ],
    },
  ],
}
